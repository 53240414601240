/** @jsx jsx */
import { jsx } from "@emotion/core"
import pluralize from "pluralize"
import React from "react"
import * as Icon from "react-feather"
import Alert from "../components/Alert"
import Button from "../components/Button"
import Select from "../components/Select"
import Checkbox from "../components/Checkbox"
import InsuranceSelect from "../components/InsuranceSelect"
import DepartureSelect from "../components/DepartureSelect"
import LeaderSelect from "../components/LeaderSelect"
import Field from "../components/Field"
import FieldRow from "../components/FieldRow"
import Input from "../components/Input"
import Radio from "../components/Radio"
import Textarea from "../components/Textarea"
import Tooltip from "../components/Tooltip"
import { useTheme } from "../theme"
import {
  deserializeMoney,
  deserializeDate,
  serializeDate,
  serializeMoney,
  serializeNumber
} from "../utils/form"
import { RED } from "../colors"

export default function TourForm({
  tour,
  offerings,
  tourCosts,
  accessCode,
  prompt = "Submit",
  onSubmit
}) {
  const { theme } = useTheme()

  const [error, setError] = React.useState(null)
  const [submitting, setSubmitting] = React.useState(false)

  const nextOfferingId = React.useRef(0)
  const [offeringIds, setOfferingIds] = React.useState([nextOfferingId.current])
  const [earlyDiscount, setEarlyDiscount] = React.useState(false)
  const [thruInsurance, setThruInsurance] = React.useState(false)
  const [feeDisabled, setFeeDisabled] = React.useState(false)

  React.useEffect(() => {
    if (offerings) {
      setOfferingIds(offerings.map(x => x.id))
    }
    if (tour) {
      setEarlyDiscount(!!tour.early_registration_discount_amount)
      setThruInsurance(!!tour.insurance_id)
      setFeeDisabled(!!tour.processing_fee_disabled)
    }
  }, [tour])

  // prettier-ignore
  async function handleSubmit(e) {
    e.preventDefault()
    const formData = new FormData(e.target)
    // prettier-ignore
    const tourData = {
      title: formData.get("tour.title"),
      total_tickets: serializeNumber(formData.get("tour.total_tickets")),
      early_registration_end_date: serializeDate(formData.get("tour.early_registration_end_date")),
      early_registration_discount_amount: serializeMoney(formData.get("tour.early_registration_discount_amount")),
      deposit_discount: !!formData.get("tour.deposit_discount"),
      deposit_amount: serializeMoney(formData.get("tour.deposit_amount")),
      deposit_discount_amount: serializeMoney(formData.get("tour.deposit_discount_amount")),
      processing_fee_disabled: feeDisabled,
      supplement_price: serializeMoney(formData.get("tour.supplement_price")),
      insurance_id: formData.get("tour.insurance_id") || null,
      insurance_link: formData.get("tour.insurance_link") || null,
      destination: formData.get("tour.destination"),
      locations: formData.get("tour.locations"),
      tour_code: String(formData.get("tour.tour_code")).toUpperCase(),
      tour_departures: offeringIds.map(id => formData.get(`offerings[${id}].tour_departure`)),
      tour_leaders: offeringIds.map(id => formData.get(`offerings[${id}].tour_leader`)),
      tour_dates: offeringIds.map(id => ({
        start: serializeDate(formData.get(`offerings[${id}].tour_start_date`)),
        end: serializeDate(formData.get(`offerings[${id}].tour_end_date`)),
      }))
    }
    const offeringsData = offeringIds.map(id => ({
      id: (offerings && offerings.find(x => x.id == id)) ? id : null,
      tour_start_date: serializeDate(formData.get(`offerings[${id}].tour_start_date`)),
      tour_end_date: serializeDate(formData.get(`offerings[${id}].tour_end_date`)),
      land_price: serializeMoney(formData.get(`offerings[${id}].land_price`)),
      air_price: serializeMoney(formData.get(`offerings[${id}].air_price`)),
      tour_leader: formData.get(`offerings[${id}].tour_leader`),
      tour_departure: formData.get(`offerings[${id}].tour_departure`),
      disable_land_air_option: formData.get(`offerings[${id}].disable_land_air_option`) == "on",
      _update_existing_registrants: formData.get(`offerings[${id}]._update_existing_registrants`) == "on",

    }))
    const tourCostsData = {
      land: serializeMoney(formData.get("tourCosts.land")),
      group_air: serializeMoney(formData.get("tourCosts.group_air")),
      local_air: serializeMoney(formData.get("tourCosts.local_air")),
      gratuity: serializeMoney(formData.get("tourCosts.gratuity")),
      supplement: serializeMoney(formData.get("tourCosts.supplement")),
      commission: serializeMoney(formData.get("tourCosts.commission")),
      headset: serializeMoney(formData.get("tourCosts.headset")),
      bottled_water: serializeMoney(formData.get("tourCosts.bottled_water")),
      tour_leader_business_class_upgrade: serializeMoney(
        formData.get("tourCosts.tour_leader_business_class_upgrade")
      ),
      ground_transfer: serializeMoney(formData.get("tourCosts.ground_transfer")),
      cruise_port_tax: serializeMoney(formData.get("tourCosts.cruise_port_tax")),
      cruise_fuel_supplement: serializeMoney(formData.get("tourCosts.cruise_fuel_supplement"))
    }
    const freeAccessData = {
      access_code: formData.get("tour_leader_access_code") || ""
    }
    try {
      setError(null)
      setSubmitting(true)
      await onSubmit(tourData, offeringsData, tourCostsData, freeAccessData)
    } catch (error) {
      setError(error)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Field
        label="Title"
        tooltip="Short description of tour. Publicly visible.">
        <Input
          name="tour.title"
          defaultValue={tour ? tour.title : null}
          autoFocus
          required
        />
      </Field>
      <Field label="Country" tooltip="Used for internal identification.">
        <Select
          fullWidth
          name="tour.destination"
          defaultValue={tour ? tour.destination : null}>
          <option value="Greece">Greece</option>
          <option value="Israel">Israel</option>
          <option value="Italy">Italy</option>
          <option value="Turkey">Turkey</option>
          <option value="Egypt">Egypt</option>
          <option value="Europe">Europe</option>
        </Select>
      </Field>
      <br />
      <h3>{pluralize("Offering", offeringIds.length)}</h3>
      {offeringIds.map(id => (
        <fieldset
          key={id}
          css={{
            position: "relative",
            marginTop: "1rem",
            padding: offeringIds.length > 1 ? "0 1rem" : 0,
            borderRadius: 3,
            border: "solid " + theme.borderColor,
            borderWidth: offeringIds.length > 1 ? 1 : 0,
            ":first-of-type": {
              marginTop: 0
            }
          }}>
          {offeringIds.length > 1 && (
            <button
              type="button"
              css={{
                position: "absolute",
                top: 0,
                right: 0,
                width: "1.5rem",
                height: "1.5rem",
                borderRadius: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: `1px solid ${theme.borderColor}`,
                backgroundColor: theme.backgroundColor,
                transform: "translate3d(50%,-50%,0)"
              }}
              onClick={() => {
                if (
                  window.confirm(
                    "Are you sure you want to remove this offering?"
                  )
                ) {
                  setOfferingIds(ids => ids.filter(x => x !== id))
                }
              }}>
              <Icon.X
                size="1rem"
                style={{ display: "block", color: "inherit" }}
              />
            </button>
          )}
          <OfferingForm
            name={`offerings[${id}]`}
            offering={offerings && offerings.find(x => x.id == id)}
          />
        </fieldset>
      ))}
      <Tooltip label="Optionally offer this tour multiple times or with different price or location parameters.">
        <Button
          type="button"
          onClick={() => {
            nextOfferingId.current++
            setOfferingIds(ids => ids.concat(nextOfferingId.current))
          }}
          size="large"
          kind="outline">
          Add another offering
        </Button>
      </Tooltip>
      <br />
      <h3>Costs</h3>
      <FieldRow>
        <Field
          label="Land Cost"
          tooltip="The internal cost of main tour land travel for each registrant.">
          <Input
            name="tourCosts.land"
            mask={Input.currencyMask}
            defaultValue={tourCosts ? deserializeMoney(tourCosts.land) : null}
            required
          />
        </Field>
        <Field
          label="Group Air Cost"
          tooltip="The internal cost of main tour air travel for each registrant that selects air travel.">
          <Input
            name="tourCosts.group_air"
            mask={Input.currencyMask}
            defaultValue={
              tourCosts ? deserializeMoney(tourCosts.group_air) : null
            }
            required
          />
        </Field>
        <Field
          label="In-Country Air Cost"
          tooltip="The internal cost of main tour local air travel in the destination country for each registrant.">
          <Input
            name="tourCosts.local_air"
            mask={Input.currencyMask}
            defaultValue={
              tourCosts ? deserializeMoney(tourCosts.local_air) : null
            }
            required
          />
        </Field>
      </FieldRow>
      <FieldRow>
        <Field
          label="Gratuity Cost"
          tooltip="The internal cost set aside for gratuity for each registrant.">
          <Input
            name="tourCosts.gratuity"
            mask={Input.currencyMask}
            defaultValue={
              tourCosts ? deserializeMoney(tourCosts.gratuity) : null
            }
            required
          />
        </Field>
        <Field
          label="Headset Cost"
          tooltip="The internal cost set aside for headsets for each registrant.">
          <Input
            name="tourCosts.headset"
            mask={Input.currencyMask}
            defaultValue={
              tourCosts ? deserializeMoney(tourCosts.headset) : null
            }
            required
          />
        </Field>
        <Field
          label="Commission Cost"
          tooltip="The internal cost set aside for commission per registrant.">
          <Input
            name="tourCosts.commission"
            mask={Input.currencyMask}
            defaultValue={
              tourCosts ? deserializeMoney(tourCosts.commission) : null
            }
            required
          />
        </Field>
      </FieldRow>
      <FieldRow>
        <Field
          label="Single Supplement Cost"
          tooltip="The internal additional cost of lodging during the main tour for a registrant that wants to room alone.">
          <Input
            name="tourCosts.supplement"
            mask={Input.currencyMask}
            defaultValue={
              tourCosts ? deserializeMoney(tourCosts.supplement) : null
            }
            required
          />
        </Field>
        <Field label="Bottled Water Cost" tooltip="">
          <Input
            name="tourCosts.bottled_water"
            mask={Input.currencyMask}
            defaultValue={
              tourCosts ? deserializeMoney(tourCosts.bottled_water) : null
            }
            required
          />
        </Field>
        <Field label="Ground Transfer" tooltip="">
          <Input
            name="tourCosts.ground_transfer"
            mask={Input.currencyMask}
            defaultValue={
              tourCosts ? deserializeMoney(tourCosts.ground_transfer) : null
            }
            required
          />
        </Field>
      </FieldRow>
      <FieldRow>
        <Field label="Cruise Port Tax">
          <Input
            name="tourCosts.cruise_port_tax"
            mask={Input.currencyMask}
            defaultValue={
              tourCosts ? deserializeMoney(tourCosts.cruise_port_tax) : null
            }
            required
          />
        </Field>
        <Field label="Cruise Fuel Supplement">
          <Input
            name="tourCosts.cruise_fuel_supplement"
            mask={Input.currencyMask}
            defaultValue={
              tourCosts
                ? deserializeMoney(tourCosts.cruise_fuel_supplement)
                : null
            }
            required
          />
        </Field>
      </FieldRow>
      <FieldRow>
        <Field label="Tour Leader Business Class Upgrade" tooltip="">
          <Input
            name="tourCosts.tour_leader_business_class_upgrade"
            mask={Input.currencyMask}
            defaultValue={
              tourCosts
                ? deserializeMoney(tourCosts.tour_leader_business_class_upgrade)
                : null
            }
            required
          />
        </Field>
        <div style={{ flex: 1 }} />
      </FieldRow>
      <br />
      <h3>Registration</h3>
      <Field
        label="Deposit Amount"
        tooltip="The amount required per registrant at registration.">
        <Input
          name="tour.deposit_amount"
          mask={Input.currencyMask}
          defaultValue={tour ? deserializeMoney(tour.deposit_amount) : null}
          required
        />
      </Field>
      <Field
        label={`Total Tickets ${tour ? `(${tour.tickets_sold} sold)` : ""}`}
        tooltip="The number of tickets available before a tour is considered sold out.">
        <Input
          name="tour.total_tickets"
          type="number"
          defaultValue={tour ? tour.total_tickets : null}
        />
      </Field>
      <Field
        label="Early Registration"
        tooltip="Whether or not this tour offers a discount for registering by some date">
        <Checkbox
          checked={earlyDiscount}
          onChange={() => setEarlyDiscount(x => !x)}>
          Offer discount
        </Checkbox>
      </Field>
      {earlyDiscount && (
        <FieldRow>
          <Field
            label="Early Registration End Date"
            tooltip="If a registration is made before this date they will receive the discount.">
            <Input
              type="date"
              name="tour.early_registration_end_date"
              defaultValue={
                tour ? deserializeDate(tour.early_registration_end_date) : null
              }
            />
          </Field>
          <Field
            label="Early Registration Discount"
            tooltip="The discount amount per registrant.">
            <Input
              name="tour.early_registration_discount_amount"
              mask={Input.currencyMask}
              defaultValue={
                tour
                  ? deserializeMoney(tour.early_registration_discount_amount)
                  : null
              }
              required
            />
          </Field>
        </FieldRow>
      )}
      <FieldRow>
        <Field
          label="Single Supplement Price"
          tooltip="An extra charge for a registrant that prefers to room alone.">
          <Input
            name="tour.supplement_price"
            mask={Input.currencyMask}
            defaultValue={tour ? deserializeMoney(tour.supplement_price) : null}
            required
          />
        </Field>
        <div style={{ flex: 1 }} />
      </FieldRow>
      <Field
        label="Processing Fees"
        tooltip="Should travelers see a credit card processing fee or not?">
        <Checkbox
          checked={feeDisabled}
          onChange={() => setFeeDisabled(x => !x)}>
          Disable Processing Fees
        </Checkbox>
      </Field>
      <br />
      <h3>Insurance</h3>
      <Field
        label="Group Insurance Options"
        tooltip="Determines whether to offer insurance as a purchase add-on, or give a link for individual traveler to use to purchase insurance outside FBE.">
        <Radio onChange={() => setThruInsurance(true)} checked={thruInsurance}>
          Group insurance can be purchased through FBE.
        </Radio>
        <Radio
          onChange={() => setThruInsurance(false)}
          checked={!thruInsurance}>
          Group insurance is sole responsibility of traveler.
        </Radio>
      </Field>
      {thruInsurance ? (
        <Field label="Insurance Pricing Group">
          <InsuranceSelect
            name="tour.insurance_id"
            defaultValue={tour ? tour.insurance_id : null}
            required
          />
        </Field>
      ) : (
        <Field label="Insurance Purchase Link">
          <Input
            type="text"
            name="tour.insurance_link"
            placeholder="https://..."
            defaultValue={tour ? tour.insurance_link : null}
          />
        </Field>
      )}
      <br />
      <h3>Extra</h3>
      <Field
        label="Registrant Locations"
        tooltip="Allow registrants to specify a location when they register. This is usually used in connection to their church, e.g. a campus or ministry.">
        <Textarea
          name="tour.locations"
          defaultValue={tour ? tour.locations : null}
        />
        <small>Enter each option on its own line.</small>
      </Field>
      <Field
        label="Tour Code"
        tooltip="Code used to access the marketing and registration pages for this tour.">
        <Input
          type="text"
          name="tour.tour_code"
          placeholder="ABC123"
          defaultValue={tour ? tour.tour_code : null}
        />
      </Field>
      <Field
        label="Tour Leader Access Code"
        tooltip="Share this code with tour leader so they can register without paying a deposit. WARNING: Anyone with this code can register for free.">
        <Input
          type="text"
          name="tour_leader_access_code"
          placeholder="SECRET123"
          defaultValue={accessCode ? accessCode.access_code : null}
        />
      </Field>
      <br />
      <Button size="large" kind="fill" disabled={submitting}>
        {submitting ? "Submitting..." : prompt}
      </Button>
      {error && <Alert error={error} />}
    </form>
  )
}

function OfferingForm({ name, offering }) {
  const [airtravel, setAirtravel] = React.useState(
    offering?.air_price > 0 || false
  )
  const [landPrice, setLandPrice] = React.useState(
    deserializeMoney(offering?.land_price)
  )
  const [airPrice, setAirPrice] = React.useState(
    deserializeMoney(offering?.air_price)
  )
  const [disableLandAirOption, setDisableLandAirOption] = React.useState(false)

  return (
    <>
      <FieldRow>
        <Field label="Start Date" tooltip="The date when the tour begins.">
          <Input
            type="date"
            defaultValue={deserializeDate(offering && offering.tour_start_date)}
            name={`${name}.tour_start_date`}
            required={true}
          />
        </Field>
        <Field label="End Date" tooltip="The date when the tour is over.">
          <Input
            type="date"
            defaultValue={deserializeDate(offering && offering.tour_end_date)}
            name={`${name}.tour_end_date`}
            required={true}
          />
        </Field>
      </FieldRow>
      <Field
        label="Tour Leader"
        tooltip="The person(s) leading the tour, if any.">
        <LeaderSelect
          name={`${name}.tour_leader`}
          defaultValue={offering ? offering.tour_leader : null}
          required
        />
      </Field>
      <FieldRow>
        <Field
          label="Travel Options"
          tooltip="Whether or not this tour offers air travel included.">
          <Radio onChange={() => setAirtravel(x => !x)} checked={!airtravel}>
            Land Only
          </Radio>
          <Radio onChange={() => setAirtravel(x => !x)} checked={airtravel}>
            Land & Air
          </Radio>
        </Field>
        <div css={{ "@media (min-width: 501px)": { margin: "-1rem 0" } }}>
          <Field
            label="Land Only Travel Price"
            tooltip="The amount charged to each registrant that opts to travel by land only.">
            <Input
              name={`${name}.land_price`}
              mask={Input.currencyMask}
              placeholder="$"
              value={landPrice}
              onChange={e => setLandPrice(e.target.value)}
              required
            />
          </Field>
          {airtravel ? (
            <Field
              label="Land & Air Travel Price"
              tooltip="The amount charged to each registrant that opts to travel by land and air.">
              <Input
                name={`${name}.air_price`}
                mask={Input.currencyMask}
                placeholder="$"
                value={airPrice}
                onChange={e => setAirPrice(e.target.value)}
                required
              />
            </Field>
          ) : (
            <div />
          )}
          {airtravel && (
            <Field
              label="Air Travel Departure"
              tooltip="The departure location for passengers that opt to travel by air included.">
              <DepartureSelect
                name={`${name}.tour_departure`}
                defaultValue={offering ? offering.tour_departure : null}
              />
            </Field>
          )}
          {airtravel && (
            <Field
              label="Land & Air Availability"
              tooltip="Once all group airline seats are sold out you can disable the Land & Air option">
              <Checkbox
                name={`${name}.disable_land_air_option`}
                checked={disableLandAirOption}
                onChange={() => setDisableLandAirOption(x => !x)}>
                Disable Land & Air option for new registrations
              </Checkbox>
            </Field>
          )}
        </div>
      </FieldRow>
      {offering &&
      (offering.land_price != serializeMoney(landPrice) ||
        offering.air_price != serializeMoney(airPrice)) ? (
        <Field
          label="Update pricing for existing travelers?"
          tooltip="Whether or not this tour offers a discount for registering by some date">
          <Checkbox name={`${name}._update_existing_registrants`}>
            <small>
              Check this box if you want to update all <i>existing</i> invoices
              to use this new tour pricing.{" "}
              <span css={{ color: RED }}>
                Warning: only check this box if all registrants have been made
                aware of the new pricing.
              </span>
            </small>
          </Checkbox>
        </Field>
      ) : null}
    </>
  )
}
